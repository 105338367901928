/* eslint-disable */
import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import scriptLoader from 'react-async-script-loader';
import UserRoles from './components/users/UserRole';
import Users from './components/users/Users';
import NavbarTable from './components/navbarTable/NavbarTable';
import './App.scss';
import './Common.scss';
import history from './history';
import AuthenticatedRoute from './components/security/AuthenticatedRoute';
import { createGlobalSession, getIdToken } from './components/security/okta';
import LoadingWrapper from './components/layouts/LoadingWrapper';
import * as appActions from './components/app/actions';
import oktaConfig from './config/okta';
import AppConfigs from './components/configs/ConfigList';
import '@partner-global-ui/components/dist/styles.min.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { actions, isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      actions.app.loadApp();
    }
    toastr.options = {
      preventDuplicates: true,
    };
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    const { actions, onError } = this.props;
    // eslint-disable-next-line
    if (isScriptLoaded && !this.props.isScriptLoaded) { // load finished
      if (isScriptLoadSucceed) {
        actions.app.loadApp();
      } else onError();
    }
  }

  componentDidUpdate(prevProps) {
    const { info, actions } = this.props;
    if (this.props !== prevProps && info !== prevProps.info) {
      sessionStorage.setItem('startupTime', new Date());
      createGlobalSession(
        oktaConfig.clientId,
        oktaConfig.environmentName,
        oktaConfig.signInUrl,
        window.location.href,
      ).then(() => {
        getIdToken().then((token) => {
          actions.app.ssoLogin(token).then((response) => {
            const redirectTimeout = 5000;
            if (response.httpStatus === 'FORBIDDEN') {
              // eslint-disable-next-line
              toastr.error(`${response.msg}. Redirecting to the Partners Hub.`, null, {
                timeOut: redirectTimeout,
                closeOnHover: false,
                tapToDismiss: false,
              });
              setTimeout(() => {
                window.location = oktaConfig.hubUrl;
              }, redirectTimeout);
            }
            if (response.httpStatus === 'UNAUTHORIZED') {
              toastr.error('Unauthorized access. Redirecting to the Partners Hub.');
              setTimeout(() => {
                window.location = oktaConfig.hubUrl;
              }, redirectTimeout);
            } else {
              this.setState({ loaded: true });
            }
          });
        });
      });
    }
  }

  getHomePage = () => {
    const { userRoles } = this.props;
    const roleNames = userRoles.map(role => role.roleName);
    const domains = [];
    userRoles.forEach((role) => {
      role.domains.forEach((domain) => {
        domains.push(domain);
      });
    });
    let homepage = 'campaigns';
    const isSuperAdmin = roleNames.includes('SUPER_ADMIN');

    if (!isSuperAdmin) {
      if (domains.includes('CHANNELS')) {
        homepage = 'channels';
      }
      if (domains.includes('CAMPAIGNS_RECOMMENDATIONS')) {
        homepage = 'campaigns';
      }
      if (domains.includes('CREATIVE')) {
        homepage = 'creative';
      }
      if (domains.includes('AUTOMERCH')) {
        homepage = 'merchandising';
      }
      if (domains.includes('EXPERIMENTATION')) {
        homepage = 'experimentation';
      }
    }
    if (isSuperAdmin) {
      homepage = 'admin/users'
    }
    return homepage;
  };

  render() {
    const { loaded } = this.state;
    const homepage = this.getHomePage();
    return (
      <LoadingWrapper loading={!loaded} minTimeForLoadDisplay={0} loadingClassName="app-auth-loading">
        <Router history={history}>
          <Switch>
            <AuthenticatedRoute path="/admin" exact component={Users} />
            <AuthenticatedRoute path="/admin/users" exact component={Users} />
            <AuthenticatedRoute path="/admin/users/user" exact component={UserRoles} />
            <AuthenticatedRoute path="/admin/users/user/:id" exact component={UserRoles} />
            <AuthenticatedRoute path="/admin/configs" exact component={AppConfigs} />
            <AuthenticatedRoute path="/admin/navbar" exact component={NavbarTable} />
            <Route path='*' component={() => {
              window.location.href = homepage;
              return null;
            }}/>
          </Switch>
        </Router>
      </LoadingWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { info, user: { userRoles } } = state.app;
  return {
    info,
    userRoles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      app: bindActionCreators(appActions, dispatch),
    },
  };
}

App.propTypes = {
  info: PropTypes.object,
  isScriptLoaded: PropTypes.bool,
  isScriptLoadSucceed: PropTypes.bool,
  onError: PropTypes.func,
  userRoles: PropTypes.array,
};

App.defaultProps = {
  info: {},
  isScriptLoaded: false,
  isScriptLoadSucceed: false,
  onError: () => {},
  userRoles: [],
};

const app = connect(mapStateToProps, mapDispatchToProps)(App);
export default scriptLoader(oktaConfig.scripts)(app);
