export default {
  signInUrl: 'https://partners.playstation.net/sign-in',
  hubUrl: 'https://partners.playstation.net/hub',
  clientId: '0oaa5snyxciKxKOQO1t7',
  environmentName: 'prod',
  scripts: [
    'https://partners.playstation.net/global-session/prod/widget.js',
    'https://partners.playstation.net/global-user-interface/prod/global-user-interface.bundle.js',
  ],
};
