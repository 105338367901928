import * as types from '../../constants/actionTypes';
import initialState from '../initialState';

export default function (state = initialState.navbar, action = {}) {
  switch (action.type) {
    case types.FETCH_NAVBAR_ITEMS: {
      return ({
        ...state,
        ...action.payload,
      });
    }
    case types.FETCH_NAVBAR_APP_NAMES: {
      return ({
        ...state,
        ...action.payload,
      });
    }
    default: {
      return state;
    }
  }
}
