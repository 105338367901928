export default {
  signInUrl: 'https://partners.sit.pt.playstation.net/sign-in',
  hubUrl: 'https://partners.sit.pt.playstation.net/hub',
  clientId: '0oagav905r4CIlWd40h7',
  environmentName: 'sit',
  scripts: [
    'https://partners.sit.pt.playstation.net/global-session/next/widget.js',
    'https://partners.sit.pt.playstation.net/global-user-interface/next/global-user-interface.bundle.js',
  ],
};
