import * as api from '../../api/api';
import * as types from '../../constants/actionTypes';

export const fetchAppConfigs = () => dispatch => api.fetchAppConfigs()
  .then((response) => {
    dispatch({
      type: types.FETCH_APP_CONFIGS,
      payload: {
        configs: response,
      },
    });
    dispatch({
      type: types.FETCH_APP_CONFIGS_SUCCESSFUL,
    });
  }).catch((error) => {
    const { response: { status = 0 } = {} } = error || {};
    if (status === 403 || status === 401) {
      dispatch({
        type: types.FETCH_APP_CONFIGS_ACCESS_DENIED,
      });
    }
  });

export const updateAppConfig = config => dispatch => api
  .updateAppConfig(config).then(response => dispatch({
    type: types.UPDATE_APP_CONFIG,
    payload: { config: response },
  }));
