import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Flex,
  Layout,
  Container,
  DataTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  Kabob,
  ActionBar,
  Button,
  TextInput,
  Select,
} from '@partner-global-ui/components';
import { Modal } from 'react-bootstrap';
import * as api from '../../api/api';
import * as types from '../../constants/actionTypes';
import './NavbarTable.scss';

const NavbarTable = () => {
  const initialNavbar = {
    parent: null,
    label: '',
    path: '',
    order: 1,
    appNames: null,
  };
  const { navbar, appNames } = useSelector(state => state.navbar);
  const dispatch = useDispatch();
  const [navbarState, setNavbarState] = useState(initialNavbar);
  const [navbarModal, setNavbarModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const getNavbarItems = async () => {
    const navRes = await api.getNavBar();
    dispatch({
      type: types.FETCH_NAVBAR_ITEMS,
      payload: {
        navbar: navRes,
      },
    });
  };

  useEffect(() => {
    setNavbarModal(false);

    const getAppNames = async () => {
      const appNameRes = await api.getAppNames();
      dispatch({
        type: types.FETCH_NAVBAR_APP_NAMES,
        payload: {
          appNames: appNameRes,
        },
      });
    };

    getNavbarItems();
    getAppNames();
  }, []);

  const getNavbarParentsOptions = () => {
    const parents = navbar.filter(nav => (!nav?.parent || Object.keys(nav?.parent) === 0));

    return parents.map(parent => ({
      label: parent?.label,
      value: {
        id: parent?.id,
        label: parent?.label,
        path: parent?.path,
      },
    }));
  };

  const getNavbarAppNamesOptions = () => appNames.map(appName => ({
    label: appName,
    value: appName,
  }));

  const formatOptionsToAppNames = (names) => {
    if (!names) return null;
    return names.map(appName => appName.value);
  };

  const formatAppNamesToOptions = (names) => {
    if (!names) return [];
    return names.map(appName => ({ label: appName, value: appName }));
  };

  const handleClose = () => {
    if (isEditing) {
      setNavbarState(initialNavbar);
      setIsEditing(false);
    }

    setNavbarModal(false);
  };

  const handleDelete = async (navId) => {
    await api.deleteNavBarItem({ id: navId });
    dispatch({
      type: types.DELETE_NAVBAR_ITEM,
    });
    getNavbarItems();
  };

  const handleSubmit = async () => {
    const formattedState = {
      ...navbarState,
      parent: (navbarState?.parent && Object.keys(navbarState?.parent).length === 0)
        ? null : navbarState?.parent,
      appNames: formatOptionsToAppNames(navbarState?.appNames),
    };

    await api.createNavBarItem(formattedState);
    dispatch({
      type: types.CREATE_NAVBAR_ITEM,
    });
    getNavbarItems();

    setNavbarModal(false);
  };

  const handleEdit = (navId) => {
    const navbarToEdit = navbar.filter(nav => nav?.id === navId)[0];

    setNavbarState({ ...navbarToEdit, appNames: formatAppNamesToOptions(navbarToEdit?.appNames) });
    setIsEditing(true);
    setNavbarModal(true);
  };

  const handleEditSubmit = async () => {
    const formattedState = {
      ...navbarState,
      appNames: formatOptionsToAppNames(navbarState?.appNames),
    };

    await api.updateNavBarItem(formattedState);
    dispatch({
      type: types.UPDATE_NAVBAR_ITEM,
    });
    getNavbarItems();

    setNavbarModal(false);
    setNavbarState(initialNavbar);
    setIsEditing(false);
  };

  return (
    <>
      <Container className="nav-bar-table-container">
        <Layout>
          <Flex>
            <DataTable
              actionBar={(
                <ActionBar
                  right={(
                    <Button primary size="sm" icon="ico-action-add" onClick={() => setNavbarModal(true)} type="button">
                      Create New
                    </Button>
                  )}
                  search=""
                />
              )}
              maxHeight="750px"
              isHeaderSticky
              isLastColumnSticky
              columnTemplate="200px 200px 200px 125px auto 100px"
            >
              <TableHeader>
                <TableHeaderCell value="parent">Parent</TableHeaderCell>
                <TableHeaderCell value="label">Label</TableHeaderCell>
                <TableHeaderCell value="path">Path</TableHeaderCell>
                <TableHeaderCell value="order">Order</TableHeaderCell>
                <TableHeaderCell value="appName">App Name</TableHeaderCell>
                <TableHeaderCell value="action">&nbsp;</TableHeaderCell>
              </TableHeader>
              {navbar.map(nav => (
                <TableRow key={`${nav?.label}-${nav?.id}`} id={nav?.id}>
                  <TableCell>{nav?.parent?.label ?? ''}</TableCell>
                  <TableCell>{nav?.label}</TableCell>
                  <TableCell>{nav?.path}</TableCell>
                  <TableCell>{nav?.order}</TableCell>
                  <TableCell>
                    {nav?.appNames?.length > 0 && nav?.appNames.join(' ')}
                  </TableCell>
                  <TableCell>
                    <Kabob
                      menuItems={[
                        {
                          disabled: false,
                          icon: null,
                          label: 'Edit',
                          onClick: () => handleEdit(nav?.id),
                        },
                        {
                          disabled: false,
                          icon: null,
                          label: 'Delete',
                          onClick: () => handleDelete(nav?.id),
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </DataTable>
          </Flex>
        </Layout>
      </Container>
      <Modal
        animation={false}
        centered
        onHide={() => handleClose()}
        show={navbarModal}
        className="nav-bar-modal"
      >
        <Modal.Header closeButton onClose={() => handleClose()}>
          <Modal.Title>{isEditing ? 'Update' : 'Create'} Navbar Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="nav-bar-modal-body">
            <Layout>
              <Flex>
                <Select
                  options={getNavbarParentsOptions()}
                  label="Parent"
                  placeholder="Select a parent (optional)"
                  required={false}
                  onChange={
                    e => setNavbarState({ ...navbarState, parent: e.target.value?.value })
                  }
                  value={navbarState?.parent ?? {}}
                />
                <TextInput
                  label="Label"
                  onChange={e => setNavbarState({ ...navbarState, label: e.target.value })}
                  placeholder="Label"
                  value={navbarState?.label}
                  size="lg"
                  type="text"
                />
                <TextInput
                  label="Order"
                  onChange={e => setNavbarState({ ...navbarState, order: e.target.value })}
                  placeholder="Order"
                  value={navbarState?.order}
                  size="lg"
                  type="number"
                />
                <TextInput
                  label="Value"
                  onChange={e => setNavbarState({ ...navbarState, path: e.target.value })}
                  placeholder="Path"
                  value={navbarState?.path}
                  size="lg"
                  type="text"
                />
                <Select
                  options={getNavbarAppNamesOptions()}
                  multiple
                  label="App Names"
                  placeholder="Select app names (optional)"
                  required={false}
                  onChange={e => setNavbarState({ ...navbarState, appNames: e.target.value })}
                  value={navbarState?.appNames ?? []}
                />
              </Flex>
            </Layout>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {isEditing ? (
            <Button onClick={() => handleEditSubmit()} type="button">Update</Button>
          ) : (
            <Button onClick={() => handleSubmit()} type="button">Create</Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavbarTable;
