import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Container, FormGroup, FormLabel, FormControl, Button, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import { NoPermissionsMessage } from '@tooling-common/components';
import usersActions from './actions';
import './Users.scss';

export class UserRole extends Component {
  state = {
    userEmail: '',
    validations: {},
    userRoles: [
      {
        roleName: '',
        domains: [],
      },
    ],
    selectedDomains: [],
    selectedRoles: [],
    activeRole: 0,
    active: true,
    isNew: true,
    locale: 'en_US',
  };

  componentDidMount() {
    const { actions, match: { params: { id } = {} } = {} } = this.props;
    if (id) {
      actions.fetchUser(id).then((res) => {
        const { payload: { res: user } } = res;
        this.setUser(user);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user, match: { params: { id } } } = this.props;
    if (id && user !== nextProps.user) {
      this.setUser(nextProps.user);
    }
  }

  setUser = (user) => {
    const { email, userRoles = [], active } = user;
    const initialUserRolesState = [
      {
        roleName: '',
        domains: [],
      },
    ];
    this.setState({
      userEmail: email,
      userRoles: userRoles.length === 0 ? initialUserRolesState : userRoles,
      activeRole: userRoles.length === 0 ? 0 : userRoles.length - 1,
      selectedDomains: [].concat(...userRoles.map(r => r.domains)),
      selectedRoles: userRoles.map(r => r.roleName),
      active,
      isNew: false,
    });
  };

  changeProp = (e, index) => {
    const { target: { name, value, checked } } = e;
    const { userRoles: roles, selectedDomains, selectedRoles } = this.state;
    let {
      userEmail, active, firstName, lastName, locale,
    } = this.state;
    if (name === 'domains') {
      if (checked) {
        roles[index].domains = [...roles[index].domains, value];
        selectedDomains.push(value);
      } else {
        roles[index].domains = [...roles[index].domains.filter(d => d !== value)];
        selectedDomains.splice(selectedDomains.indexOf(value));
      }
    } else if (name === 'roleName') {
      if (roles[index][name].length === 0) {
        selectedRoles.push(value);
      } else {
        selectedRoles.splice(selectedRoles.indexOf(value));
      }
      roles[index][name] = value;
    } else if (name === 'userEmail') {
      userEmail = value;
    } else if (name === 'status') {
      active = value;
    } else if (name === 'userFirstName') {
      firstName = value;
    } else if (name === 'userLastName') {
      lastName = value;
    } else if (name === 'userLocale') {
      locale = value;
    }
    this.setState({
      userRoles: roles,
      selectedDomains,
      selectedRoles,
      userEmail,
      active,
      firstName,
      lastName,
      locale,
    });
  };

  validate = () => {
    const { userEmail, userRoles } = this.state;
    const validations = {};
    if (userEmail.length === 0) validations.userEmail = 'Email is required.';
    userRoles.forEach((role) => {
      if (role.roleName.length === 0) validations.roleName = 'Role name is required for each role.';
      if (role.domains.length === 0 && role.roleName !== 'SUPER_ADMIN') {
        validations.domains = 'At least one domain is required for each role.';
      }
      if (role.roleName === 'SIE_MODERATOR' && (role.domains.length > 1 || role.domains.indexOf('CAMPAIGNS_OFFICIAL_NEWS') === -1)) {
        validations.domains = 'CAMPAIGNS_OFFICIAL_NEWS domain can only be selected for SIE_MODERATOR role.';
      }
    });
    return validations;
  };

  saveUser = () => {
    const { actions, history } = this.props;
    const {
      userEmail, userRoles, active, firstName, lastName, locale,
    } = this.state;
    if (Object.keys(this.validate()).length === 0) {
      actions.saveUserRole({
        userRoles,
        userEmail,
        active,
        firstName,
        lastName,
        locale,
      }).then((response) => {
        const { payload: { res: { oktaId } } } = response;
        toastr.success(`User role has been ${oktaId ? 'updated' : 'created'} successfully.`);
        history.push('/admin/users');
      });
    } else {
      this.setState({
        validations: this.validate(),
      });
    }
  };

  cancel = () => {
    const { history } = this.props;
    history.push('/admin/users');
  };

  addRole = () => {
    const { userRoles, activeRole } = this.state;
    this.setState({
      userRoles: [
        ...userRoles,
        {
          roleName: '',
          domains: [],
        },
      ],
      activeRole: activeRole + 1,
    });
  };

  removeRole = (role) => {
    const { userRoles, activeRole, selectedRoles } = this.state;
    let { selectedDomains } = this.state;
    userRoles.pop();
    if (role.roleName.length > 0) {
      selectedRoles.splice(selectedRoles.indexOf(role.roleName));
    }
    if (role.domains.length > 0) {
      selectedDomains = selectedDomains.filter(r => !role.domains.includes(r));
    }
    this.setState({
      userRoles,
      activeRole: activeRole - 1,
      selectedDomains,
      selectedRoles,
    });
  };

  render() {
    const {
      userRoles, domains, fetchUserSuccess, fetchUserAccessDenied,
    } = this.props;
    const {
      userEmail, userRoles: roles, validations, selectedDomains, selectedRoles,
      activeRole, active, isNew, userFirstName, userLastName, locale,
    } = this.state;
    if (fetchUserAccessDenied) {
      return <NoPermissionsMessage message="You currently are not assigned permissions to Admin." />;
    }

    if (!fetchUserSuccess && !isNew) {
      return null;
    }
    return (
      <Container className="page-container users-container">
        <h1 className="page-title" data-test-tag="users-title">User Roles</h1>
        <div className="page-content">
          { isNew && (
          <div>
            <FormGroup className={validations.firstName ? 'has-error' : ''}>
              <FormLabel>First Name</FormLabel>
              <FormControl
                onChange={this.changeProp}
                name="userFirstName"
                value={userFirstName}
                data-test-tag="user-first-name-input"
              />
            </FormGroup>

            <FormGroup className={validations.lastName ? 'has-error' : ''}>
              <FormLabel>Last Name</FormLabel>
              <FormControl
                onChange={this.changeProp}
                name="userLastName"
                value={userLastName}
                data-test-tag="user-last-name-input"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Language Preference</FormLabel>
              <FormControl
                onChange={this.changeProp}
                name="userlocale"
                as="select"
                value={locale}
                data-test-tag="user-local-input"
              >
                <option value="en_US">English</option>
                <option value="ja_JP">Japanese 日本語</option>
              </FormControl>
            </FormGroup>
          </div>
          )}

          <FormGroup className={validations.userEmail ? 'has-error' : ''}>
            <FormLabel>User Email</FormLabel>
            <FormControl
              onChange={this.changeProp}
              name="userEmail"
              value={userEmail}
              data-test-tag="user-role-email-input"
              disabled={!isNew}
            />
            {validations.userEmail
            && <div className="error-msg" data-test-tag="user-email-error-msg">{validations.userEmail}</div>}
            {validations.roleName
            && (
              <div className="has-error">
                <div className="error-msg" data-test-tag="user-role-name-error-msg">{validations.roleName}</div>
              </div>
            )}
            {validations.domains
            && (
              <div className="has-error">
                <div className="error-msg" data-test-tag="user-role-name-error-msg">{validations.domains}</div>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel>Status</FormLabel>
            <FormControl
              onChange={this.changeProp}
              name="status"
              as="select"
              value={active}
              data-test-tag="user-role-status"
            >
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </FormControl>
          </FormGroup>
          {roles.map((role, index) => (
            <Fragment key={role.roleName}>
              <hr />
              <FormGroup className={validations.roleName && role.roleName.length === 0 ? 'has-error' : ''}>
                <FormLabel>Role</FormLabel>
                <FormControl
                  as="select"
                  onChange={e => this.changeProp(e, index)}
                  name="roleName"
                  value={role.roleName}
                  data-test-tag={`user-role-${index}-rolename-select`}
                  disabled={activeRole !== index}
                >
                  <option />
                  {userRoles.map((r) => {
                    const showOption = !selectedRoles.includes(r) || role.roleName === r;
                    return showOption && (
                      <option key={r} value={r}>{r}</option>
                    );
                  })}
                </FormControl>
              </FormGroup>
              {role.roleName.length > 0 && role.roleName !== 'SUPER_ADMIN' && (
                <FormGroup className={validations.domains && role.domains.length === 0 ? 'has-error' : ''}>
                  <FormLabel>Domains</FormLabel>
                  <div>
                    {domains.map(domain => (
                      <Form.Check
                        key={domain}
                        id={`user-role-${index}-${domain}-checkbox`}
                        type="checkbox"
                        label={domain}
                        value={domain}
                        name="domains"
                        onChange={e => this.changeProp(e, index)}
                        data-test-tag={`user-role-${domain}-checkbox`}
                        checked={role.domains.includes(domain)}
                        disabled={(selectedDomains
                          .includes(domain) && !role.domains
                          .includes(domain)) || activeRole !== index}
                      />
                    ))}
                  </div>
                </FormGroup>
              )}
              {(roles[index].domains.length > 0 || role.roleName === 'SUPER_ADMIN') && index === activeRole
              && selectedDomains.length !== domains.length && (
                <Button
                  variant="link"
                  onClick={this.addRole}
                  block
                  data-test-tag="user-role-add-role"
                >
                  + Add another role
                </Button>
              )}
              {index > 0 && activeRole === index && (
                <Button
                  variant="link"
                  onClick={() => this.removeRole(role)}
                  block
                  className="remove-role"
                  data-test-tag="user-role-remove-role"
                >
                  - Remove this role
                </Button>
              )}
            </Fragment>
          ))}
          <Button
            onClick={this.saveUser}
            variant="primary"
            className="pull-right"
            data-test-tag="user-role-save-button"
          >
            Save
          </Button>
          <Button
            onClick={this.cancel}
            variant="outline-primary"
            className="pull-right cancel-button"
            data-test-tag="user-role-cancel-button"
          >
            Cancel
          </Button>
          <div style={{ clear: 'both' }} />
        </div>
      </Container>
    );
  }
}

export function mapStateToProps(state) {
  const {
    app: { userRoles, domains }, users: { users, fetchUserSuccess, fetchUserAccessDenied },
  } = state;
  return {
    userRoles,
    domains,
    users,
    fetchUserSuccess,
    fetchUserAccessDenied,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(usersActions, dispatch),
  };
}

UserRole.propTypes = {
  userRoles: PropTypes.array,
  domains: PropTypes.array,
  user: PropTypes.object,
  fetchUserSuccess: PropTypes.bool,
  fetchUserAccessDenied: PropTypes.bool,
};

UserRole.defaultProps = {
  userRoles: [],
  domains: [],
  user: {},
  fetchUserSuccess: false,
  fetchUserAccessDenied: false,
};

UserRole.displayName = 'UserRole';

export default connect(mapStateToProps, mapDispatchToProps)(UserRole);
