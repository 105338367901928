import toastr from 'toastr';
import { logout, ssoLogin } from '../app/actions';
import store from '../../store';

// eslint-disable-next-line import/no-mutable-exports
let globalSession;

const redirectToLogin = (loginUri, redirectUri) => {
  window.location = `${loginUri}?redirectUrl=${encodeURI(redirectUri)}`;
};

const checkAuthentication = () => {
  if (globalSession) {
    return globalSession.getSessionInfo()
      .then(info => Promise.resolve(info.loggedIn))
      .catch(error => Promise.reject(error));
  }

  return Promise.reject(new Error('No global session'));
};

const getIdToken = () => {
  if (globalSession) {
    return globalSession.getIdToken()
      .then(token => Promise.resolve(token))
      .catch(error => Promise.reject(error));
  }

  return Promise.reject(new Error('No global session'));
};

const getAuthToken = () => globalSession.getIdToken();

const createGlobalSession = (clientId, env, loginUri, redirectUri) => new Promise((resolve) => {
  globalSession = window.globalSessionWidget.init({
    clientId,
    env,
    signoutCallback: () => {
      if (sessionStorage.getItem('csrfToken')) {
        store.dispatch(logout()).then(() => {
          redirectToLogin(loginUri, redirectUri);
        });
      }
    },
    signinCallback: () => {
      getIdToken().then((token) => {
        store.dispatch(ssoLogin(token)).then((response) => {
          if (response.httpStatus === 'UNAUTHORIZED') {
            toastr.error(response.msg);
          }
        });
      });
    },
    localSessionRefresh: () => {
      // initial call to ssoLogin is in App.js
      const now = new Date();
      const startup = new Date(sessionStorage.getItem('startupTime'));
      const secondsSinceStartup = ((now - startup) / 1000);
      // prevents ssoLogin from being called too soon after startup
      // which can result in 403 errors on subsequent API calls
      if (sessionStorage.getItem('csrfToken') && secondsSinceStartup > 30) {
        getIdToken().then((token) => {
          store.dispatch(ssoLogin(token)).then((response) => {
            if (response.httpStatus === 'UNAUTHORIZED') {
              toastr.error(response.msg);
            }
          });
        });
      }
    },
  });

  return checkAuthentication()
    .then((authenticated) => {
      if (authenticated) {
        // initialize global header
        window.PartnerGlobalUserInterface.init({
          headerSelector: '#globalHeader',
          footerSelector: '#globalFooter',
          globalSession,
          env,
          applicationId: 'intelligenceplatform',
          showHelpLink: true,
        });
        // everything created correctly
        resolve();
      } else {
        // if the user isn't actually authenticated, have to redirect to the global login page
        redirectToLogin(loginUri, redirectUri);
      }
    });
});

export {
  createGlobalSession, checkAuthentication, globalSession, getIdToken, getAuthToken,
};
