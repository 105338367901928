import * as api from '../../api/api';
import * as types from '../../constants/actionTypes';
import baseUrl from '../../config/baseUrl';

export const loadApp = () => (dispatch) => {
  api.getVersionInfo().then((response) => {
    dispatch({
      type: types.LOAD_APP,
      payload: { data: response.data },
    });
  });
};

export const ssoLogin = idToken => dispatch => fetch(`${baseUrl}/v1/api/ssologin`,
  {
    method: 'GET',
    headers: {
      'X-AUTH-TOKEN': idToken,
    },
    credentials: 'include',
  })
  .then(response => response.json())
  .then((response) => {
    sessionStorage.setItem('csrfToken', response.csrfToken);
    const { user } = response;
    dispatch({
      type: types.LOAD_USER,
      payload: { user: user || {}, idToken },
    });
    return response;
  });

export function logout() {
  const csrfToken = sessionStorage.getItem('csrfToken');
  sessionStorage.removeItem('csrfToken');
  return () => fetch(`${baseUrl}/logout`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify({}),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then(response => response.json())
    .catch(() => {
    });
}
