import React, { Component } from 'react';
import {
  Container, Table, Row, Col, Button, FormControl,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { NoPermissionsMessage } from '@tooling-common/components';
import usersActions from './actions';
import User from './User';
import NumberedPagination from '../common/NumberedPagination';
import './Users.scss';

export class Users extends Component {
  state = {
    pagination: {
      offset: 0,
      limit: 5,
      sortOrder: 'asc',
      sort: 'email',
    },
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.loadUsers(pagination);
  }

  changePage = (offset, limit) => {
    const { pagination, search } = this.state;
    this.setState({
      pagination: {
        ...pagination,
        offset,
        limit,
      },
    }, () => {
      const { pagination: newPagination } = this.state;
      if (search) {
        this.searchUsers({ target: { value: search } });
      } else {
        this.loadUsers(newPagination);
      }
    });
  };

  sortUsers = (e, name) => {
    if (typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    const { pagination, pagination: { sort, sortOrder } } = this.state;
    const newSortOrder = name === sort ? sortOrder === 'asc' ? 'desc' : 'asc' : name === 'active' ? 'desc' : 'asc';
    this.setState({
      pagination: {
        ...pagination,
        sort: name,
        sortOrder: newSortOrder,
      },
    }, () => {
      this.loadUsers();
    });
  };

  searchUsers = (e) => {
    const { target: { value } } = e;
    const { pagination } = this.state;
    const { actions } = this.props;
    this.setState({
      search: value,
    }, () => {
      actions.searchUsers({
        ...pagination,
        email: value,
      });
    });
  };

  loadUsers() {
    const { actions } = this.props;
    const { pagination } = this.state;
    actions.fetchUsers(pagination);
  }

  render() {
    const {
      users, history, count, fetchUsersSuccess, fetchUsersAccessDenied,
    } = this.props;
    const { pagination: { sortOrder, sort }, search } = this.state;
    if (fetchUsersAccessDenied) {
      return <NoPermissionsMessage message="You currently are not assigned permissions to Admin." />;
    }

    if (!fetchUsersSuccess) {
      return null;
    }
    return (
      <Container className="page-container users-container">
        <Row>
          <Col sm={12}>
            <div className="page-title-section page-header">
              <Row>
                <Col sm={7}>
                  <div className="page-title" data-test-tag="users-title">Users</div>
                </Col>
                <Col sm={3}>
                  <FormControl
                    onChange={this.searchUsers}
                    name="userSearch"
                    value={search}
                    data-test-tag="user-search-input"
                    placeholder="Search users by email"
                  />
                </Col>
                <Col sm={2}>
                  <Button
                    variant="primary"
                    className="primary-button-common create-button pull-right"
                    data-test-tag="create-user"
                    onClick={() => history.push('/admin/users/user')}
                  >
                    <span>+</span> New User
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Table hover className="users-table">
          <tbody>
            <tr className="user-list-header">
              <th
                data-test-tag="user-email-header"
                className={`sortable-header ${(sort === 'email' && sortOrder) ? sortOrder : ''}`}
                onClick={e => this.sortUsers(e, 'email')}
              >
                Email
              </th>
              <th
                data-test-tag="user-role-header"
              >
                Roles/Domains
              </th>
              <th
                data-test-tag="user-status-header"
                className={`sortable-header reverse-arrow ${(sort === 'active' && sortOrder) ? sortOrder : ''}`}
                onClick={e => this.sortUsers(e, 'active')}
              >
                Status
              </th>
            </tr>
            {users.map(user => (
              <User
                id={user.email}
                user={user}
                key={user.email}
                history={history}
              />
            ))}
          </tbody>
        </Table>
        <NumberedPagination
          changePage={this.changePage}
          label="users"
          totalNumberOfItems={count}
          paginationLimits={[5, 10, 25]}
        />
      </Container>
    );
  }
}

export function mapStateToProps(state) {
  const {
    users: {
      users, totalElements: count, fetchUsersSuccess, fetchUsersAccessDenied,
    },
  } = state;
  return {
    users,
    count,
    fetchUsersSuccess,
    fetchUsersAccessDenied,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(usersActions, dispatch),
  };
}

Users.propTypes = {
  users: PropTypes.array,
  count: PropTypes.number,
  fetchUsersSuccess: PropTypes.bool,
  fetchUsersAccessDenied: PropTypes.bool,
};

Users.defaultProps = {
  users: [],
  count: 0,
  fetchUsersSuccess: false,
  fetchUsersAccessDenied: false,
};

Users.displayName = 'Users';

export default connect(mapStateToProps, mapDispatchToProps)(Users);
