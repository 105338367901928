/* eslint-disable camelcase */
import toastr from 'toastr';
import axios from 'axios';
import { checkAuthentication, getAuthToken } from '../components/security/okta';
import baseUrl from '../config/baseUrl/index';

/*
/////////////////////////////////////
// Configurations
/////////////////////////////////////
*/

const api = axios.create({
  withCredentials: true,
  baseURL: `${baseUrl}/v1/api`,
});

api.interceptors.request.use(
  (config = { headers: {} }) => checkAuthentication().then((authenticated) => {
    if (authenticated) {
      return getAuthToken().then(() => ({
        ...config,
        headers: {
          ...config.headers,
          'X-CSRF-TOKEN': sessionStorage.getItem('csrfToken'),
          'Content-Type': 'application/json',
        },
      }));
    }
    return Promise.reject(new Error('Not authenticated'));
  }),
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => response.data,
  (error) => {
    if (error.response) {
      const {
        response: { status, data: { error_description } },
      } = error;
      switch (status) {
        case 400:
        case 409:
        case 500:
          toastr.error(`ERROR: ${error_description}`);
          return Promise.reject(error);
        case 401:
          return Promise.reject(new Error('Unauthorized user'));
        case 418:
          return Promise.reject(new Error('Maintenance mode'));
        default:
          return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

function queryStringify(params) {
  if (typeof params !== 'object') {
    return null;
  }
  return Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
}

/*
/////////////////////////////////////
// User Role APIs
/////////////////////////////////////
*/

export function saveUserRole(user) {
  return api.post('/userRole', user);
}

/*
/////////////////////////////////////
// API to get version
/////////////////////////////////////
*/

export function getVersionInfo() {
  return axios.get(`${baseUrl}/internal/info`);
}


/*
/////////////////////////////////////
// Application Configs APIs
/////////////////////////////////////
*/
export function fetchAppConfigs() {
  return api.get('/appConfigs');
}

export function updateAppConfig(config) {
  return api.put('/appConfigs', config);
}

/*
/////////////////////////////////////
// USERS APIs
/////////////////////////////////////
*/

export function getUsers(params) {
  return api.get(`/users?${queryStringify(params)}`);
}

export function getUser(id) {
  return api.get(`/users/${id}`);
}

export function searchUsers(params) {
  return api.get(`/users/search?${queryStringify(params)}`);
}

/*
/////////////////////////////////////
// Navbar APIs
/////////////////////////////////////
*/

export function getNavBar() {
  return api.get('/menuItems');
}

export function createNavBarItem(params) {
  return api.post('/menuItems', params);
}

export function updateNavBarItem(params) {
  return api.put('/menuItems', params);
}

export function deleteNavBarItem(params) {
  return api.delete('/menuItems', { data: params });
}

export function getAppNames() {
  return api.get('/appNames');
}
