export const LOAD_APP = 'LOAD_APP';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_NAVBAR = 'LOAD_NAVBAR';

export const SAVE_USER_ROLE = 'users/SAVE_USER_ROLE';
export const FETCH_USERS = 'users/FETCH_USERS';
export const FETCH_USERS_SUCCESSFUL = 'users/FETCH_USERS_SUCCESSFUL';
export const FETCH_USERS_ACCESS_DENIED = 'users/FETCH_USERS_ACCESS_DENIED';
export const SEARCH_USERS = 'users/SEARCH_USERS';
export const FETCH_USER = 'users/FETCH_USER';
export const FETCH_USER_SUCCESSFUL = 'users/FETCH_USER_SUCCESSFUL';
export const FETCH_USER_ACCESS_DENIED = 'users/FETCH_USER_ACCESS_DENIED';

export const FETCH_APP_CONFIGS = 'configs/FETCH_APP_CONFIGS';
export const FETCH_APP_CONFIGS_SUCCESSFUL = 'configs/FETCH_APP_CONFIGS_SUCCESSFUL';
export const FETCH_APP_CONFIGS_ACCESS_DENIED = 'configs/FETCH_APP_CONFIGS_ACCESS_DENIED';
export const UPDATE_APP_CONFIG = 'configs/UPDATE_APP_CONFIG';

export const FETCH_NAVBAR_ITEMS = 'navbar/FETCH_NAVBAR_ITEMS';
export const FETCH_NAVBAR_SUCCESSFUL = 'navbar/FETCH_NAVBAR_SUCCESSFUL';
export const FETCH_NAVBAR_ACCESS_DENIED = 'navbar/FETCH_NAVBAR_ACCESS_DENIED';
export const FETCH_NAVBAR_APP_NAMES = 'navbar/FETCH_NAVBAR_APP_NAMES';
export const CREATE_NAVBAR_ITEM = 'navbar/CREATE_NAVBAR_ITEM';
export const UPDATE_NAVBAR_ITEM = 'navbar/UPDATE_NAVBAR_ITEM';
export const DELETE_NAVBAR_ITEM = 'navbar/DELETE_NAVBAR_ITEM';
