import { LOAD_APP, LOAD_USER } from '../../constants/actionTypes';
import initialState from '../initialState';

export default function (state = initialState.app, action = {}) {
  switch (action.type) {
    case LOAD_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOAD_APP: {
      return {
        ...state,
        info: {
          env: action.payload.data.app.env,
          buildTime: action.payload.data.app.buildTime,
          version: action.payload.data.app.version,
          commitId: action.payload.data.git.commit.id,
          branch: action.payload.data.git.branch,
        },
      };
    }
    default: {
      return state;
    }
  }
}
