import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export class User extends Component {
  loadUserPage = () => {
    const { user: { userId }, history } = this.props;
    history.push(`/admin/users/user/${userId}`);
  };

  render() {
    const {
      user: {
        email,
        userRoles = [],
        active,
      },
    } = this.props;
    return (
      <tr data-test-tag="user-row" className="user-row">
        <td>
          <Button
            variant="link"
            onClick={this.loadUserPage}
            data-test-tag="user-email"
          >
            {email}
          </Button>
        </td>
        <td className="roles-table">
          <table>
            <tbody>
              {userRoles.map(role => (
                <tr key={role.roleName}>
                  <td
                    style={{ fontWeight: 'bold' }}
                  >
                    <span data-test-tag="user-role-name">{role.roleName}</span>
                  </td>
                  <td>
                    <span data-test-tag="user-role-domains">{role.domains.join(', ')}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
        <td>
          <span data-test-tag="user-status">
            {active ? 'Active' : 'Inactive'}
          </span>
        </td>
      </tr>
    );
  }
}

User.propTypes = {
  user: PropTypes.object,
};

User.defaultProps = {
  user: {},
};

User.displayName = 'User';

export default User;
