const prodUrls = [
  'activate.playstation.net',
  'iptools-p2.np.tools.playstation.net',
];
const isProd = prodUrls.includes(window.location.host);
if (isProd) {
  module.exports = require('./oktaConfig.prod');
} else {
  module.exports = require('./oktaConfig.dev');
}
