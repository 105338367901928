import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Container, Table } from 'react-bootstrap';
import toastr from 'toastr';
import { NoPermissionsMessage } from '@tooling-common/components';
import * as appConfigActions from './actions';
import Config from './Config';

export class ConfigList extends Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.fetchAppConfigs();
  }

  showToastrSuccess = () => {
    toastr.success('Config successfully saved.');
  };

  render() {
    const {
      configs, actions, fetchAppConfigsSuccess, fetchAppConfigsAccessDenied,
    } = this.props;
    if (fetchAppConfigsAccessDenied) {
      return <NoPermissionsMessage message="You currently are not assigned permissions to Admin." />;
    }

    if (!fetchAppConfigsSuccess) {
      return null;
    }
    return (
      <Container className="page-container" data-test-tag="app-config-page">
        <div className="page-title mt-3 mb-3" data-test-tag="app-config-title">Application Configs</div>
        <Table striped hover className="border border-light" data-test-tag="app-config-table">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
            {configs.map(c => (
              <Config
                key={c.key}
                config={c}
                updateConfig={actions.updateAppConfig}
                showToastrSuccess={this.showToastrSuccess}
              />
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }
}

export function mapStateToProps(state) {
  const { configs: { configs, fetchAppConfigsSuccess, fetchAppConfigsAccessDenied } } = state;
  return {
    configs,
    fetchAppConfigsSuccess,
    fetchAppConfigsAccessDenied,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appConfigActions, dispatch),
  };
}

ConfigList.propTypes = {
  configs: PropTypes.array,
  fetchAppConfigsSuccess: PropTypes.bool,
  fetchAppConfigsAccessDenied: PropTypes.bool,
};

ConfigList.defaultProps = {
  configs: [],
  fetchAppConfigsSuccess: false,
  fetchAppConfigsAccessDenied: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigList);
