export default {
  app: {
    user: {
      userRoles: [],
    },
    info: {},
    userRoles: [
      'SUPER_ADMIN',
      'SIE_ADMIN',
      'SIE_OPERATOR',
      'SIE_ANALYST',
      'SIE_MODERATOR',
      'PARTNER_ADMIN',
      'PARTNER_OPERATOR',
      'PARTNER_ANALYST',
    ],
    domains: [
      'AUTOMERCH',
      'CHANNELS',
      'CREATIVE',
      'CAMPAIGNS_RECOMMENDATIONS',
      'EXPERIMENTATION',
      'PROGRAMMATIC',
    ],
  },
  configs: {
    configs: [],
    config: {},
  },
  users: {
    users: [],
  },
  navbar: {
    navbar: [],
    appNames: [],
  },
};
