import { combineReducers } from 'redux';
import appReducer from './common/appReducer';
import configsReducer from './configs/configsReducer';
import usersReducer from './users/usersReducer';
import navbarReducer from './navbar/navbarReducer';

export default combineReducers({
  app: appReducer,
  configs: configsReducer,
  users: usersReducer,
  navbar: navbarReducer,
});
