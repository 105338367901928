import React, { Component } from 'react';
import {
  Pagination, Col, Row, FormControl, FormGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './NumberedPagination.scss';

class NumberedPagination extends Component {
  constructor(props) {
    super(props);
    const { paginationLimits } = props;
    this.state = {
      currentPage: 1,
      itemsPerPage: paginationLimits[0],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { totalNumberOfItems } = this.props;
    // go to page 1 on filter
    if (totalNumberOfItems !== nextProps.totalNumberOfItems) {
      this.setState({
        currentPage: 1,
      });
    }
  }

  renderLabel = () => {
    const { totalNumberOfItems, label } = this.props;
    const { itemsPerPage, currentPage } = this.state;
    if (totalNumberOfItems > 0) {
      const lastShownItemNumber = currentPage * itemsPerPage <= totalNumberOfItems
        ? currentPage * itemsPerPage : totalNumberOfItems;
      return (
        <span data-test-tag={`${this.getDataTestTagLabel()}-numbered-pagination-label`}>
          Showing
          <strong> {itemsPerPage * (currentPage - 1) + 1} - {lastShownItemNumber}
          </strong> of <strong>{totalNumberOfItems}</strong> {label}
        </span>
      );
    }
    return (
      <span data-test-tag={`${this.getDataTestTagLabel()}-numbered-pagination-label`}>
        <strong>No {label} to display!</strong>
      </span>
    );
  };

  renderItemsPerPageDropdown = () => {
    const { paginationLimits } = this.props;
    const { itemsPerPage } = this.state;
    return (
      <FormGroup className="select-pagination-option-container">
        <FormControl
          id="selectedPaginationOption"
          as="select"
          value={itemsPerPage}
          onChange={e => this.handleItemsPerPageChange(e)}
          data-test-tag={`${this.getDataTestTagLabel()}-pagination-items-per-page-dropdown`}
        >
          {paginationLimits.length
            ? paginationLimits
              .map(option => (
                <option value={option} key={option}>
                  Display {option} rows
                </option>
              )) : null}
        </FormControl>
      </FormGroup>
    );
  };

    renderPagination = () => {
      const { currentPage, itemsPerPage } = this.state;
      const { totalNumberOfItems } = this.props;
      const numberOfPages = this.getNumberOfPages();
      return (
        <Pagination className="pull-right">
          {currentPage !== 1 && (
          <Pagination.Item
            onClick={() => this.handlePageChange(currentPage - 1)}
            data-test-tag={`${this.getDataTestTagLabel()}-numbered-pagination-previous-page-button`}
            key={-1}
          >Previous
          </Pagination.Item>
          )}
          {this.renderPageNumberButtons()}
          {currentPage !== numberOfPages
          && (
          <Pagination.Item
            disabled={totalNumberOfItems < itemsPerPage}
            onClick={() => this.handlePageChange(currentPage + 1)}
            data-test-tag={`${this.getDataTestTagLabel()}-numbered-pagination-next-page-button`}
            key={-2}
          >Next
          </Pagination.Item>
          )}
        </Pagination>
      );
    };

  renderPageNumberButtons = () => {
    const { currentPage } = this.state;
    const numberOfPages = this.getNumberOfPages();
    const pageNumberButtons = [];
    if (numberOfPages <= 10) {
      this.addPaginationItems(pageNumberButtons, 0, numberOfPages + 1);
    } else {
      let lowerLimit = 1;
      let upperLimit = 11;
      if (currentPage > 5) {
        upperLimit = currentPage + 5 < numberOfPages ? currentPage + 5 : numberOfPages;
        lowerLimit = currentPage - 5;
      }
      pageNumberButtons.push(
        <Pagination.Item
          key={1}
          active={currentPage === 1}
          onClick={() => this.handlePageChange(1)}
          data-test-tag={`${this.getDataTestTagLabel()}-numbered-pagination-page-button-${1}`}
        >
          1
        </Pagination.Item>,
      );
      if (currentPage > 6) {
        this.addEllipsisButton(pageNumberButtons, true);
      }
      this.addPaginationItems(pageNumberButtons, lowerLimit, upperLimit);
      if (currentPage <= numberOfPages - 6) {
        this.addEllipsisButton(pageNumberButtons, false);
      }
      pageNumberButtons.push(
        <Pagination.Item
          key={numberOfPages}
          active={numberOfPages === currentPage}
          onClick={() => this.handlePageChange(numberOfPages)}
          data-test-tag={`${this.getDataTestTagLabel()}-numbered-pagination-page-button-${numberOfPages}`}
        >
          {numberOfPages}
        </Pagination.Item>,
      );
    }
    return pageNumberButtons;
  };

  handlePageChange = (newActivePage) => {
    const { changePage } = this.props;
    const { itemsPerPage } = this.state;
    this.setState(
      {
        currentPage: newActivePage,
      }, changePage(itemsPerPage * (newActivePage - 1), itemsPerPage),
    );
  };

  handleItemsPerPageChange = (event) => {
    const { changePage } = this.props;
    const limit = event.target.value;
    this.setState(
      {
        itemsPerPage: limit,
        currentPage: 1,
      }, changePage(0, limit),
    );
  };

  getNumberOfPages = () => {
    const { totalNumberOfItems } = this.props;
    const { itemsPerPage } = this.state;
    return Math.ceil(totalNumberOfItems / itemsPerPage);
  };

  getDataTestTagLabel = () => {
    const { label } = this.props;
    return label.replace(/\s+/g, '-');
  };

  addPaginationItems = (paginationItems, from, to) => {
    const { currentPage } = this.state;
    for (let i = from + 1; i < to; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => this.handlePageChange(i)}
          data-test-tag={`${this.getDataTestTagLabel()}-numbered-pagination-page-button-${i}`}
        >
          {i}
        </Pagination.Item>,
      );
    }
  };

  addEllipsisButton = (paginationItems, isStart) => {
    paginationItems.push(
      <Pagination.Item
        key={`${isStart ? '-3' : '-4'}`}
        className="ellipsis"
        data-test-tag={`${this.getDataTestTagLabel()}-numbered-pagination-ellipsis`}
      >
        ...
      </Pagination.Item>,
    );
  };

  render() {
    return (
      <Row className="numbered-pagination-container">
        <Col sm={5} className="pagination-label-container">
          {this.renderLabel()}
          {this.renderItemsPerPageDropdown()}
        </Col>
        <Col sm={7}>{this.renderPagination()}</Col>
      </Row>
    );
  }
}

NumberedPagination.propTypes = {
  changePage: PropTypes.func,
  totalNumberOfItems: PropTypes.number,
  label: PropTypes.string,
  paginationLimits: PropTypes.array,
};

NumberedPagination.defaultProps = {
  changePage: () => {},
  totalNumberOfItems: 0,
  label: 'items',
  paginationLimits: [5, 10, 25],
};

NumberedPagination.displayName = 'NumberedPagination';

export default NumberedPagination;
