import * as types from '../../constants/actionTypes';
import initialState from '../initialState';

export default function (state = initialState.users, action = {}) {
  switch (action.type) {
    case types.SEARCH_USERS:
    case types.FETCH_USERS: {
      return ({
        ...state,
        ...action.payload.users,
      });
    }
    case types.FETCH_USERS_SUCCESSFUL: {
      return ({
        ...state,
        fetchUsersSuccess: true,
      });
    }
    case types.FETCH_USERS_ACCESS_DENIED: {
      return ({
        ...state,
        fetchUsersAccessDenied: true,
      });
    }
    case types.FETCH_USER_SUCCESSFUL: {
      return ({
        ...state,
        fetchUserSuccess: true,
      });
    }
    case types.FETCH_USER_ACCESS_DENIED: {
      return ({
        ...state,
        fetchUserAccessDenied: true,
      });
    }
    default: {
      return state;
    }
  }
}
