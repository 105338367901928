import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormControl, Tooltip, OverlayTrigger,
} from 'react-bootstrap';

export default class Config extends Component {
  constructor(props) {
    super(props);
    const { config } = props;
    this.state = {
      isEdited: false,
      config,
    };
  }

  changeConfigValue = (e) => {
    const { target: { value } } = e;
    const { config } = this.state;
    const updatedConfig = {
      ...config,
      value: value.trim(),
    };
    this.setState({
      isEdited: true,
      config: updatedConfig,
    });
  };

  handleSave = () => {
    const { updateConfig, showToastrSuccess } = this.props;
    const { config } = this.state;
    updateConfig(config).then((response) => {
      if (response) {
        showToastrSuccess();
      }
    });
    this.setState({
      isEdited: false,
    });
  };

  renderDescriptionTooltip = () => {
    const { config } = this.state;
    const descriptionTooltip = (
      <Tooltip>
        <div
          data-test-tag="app-config-description-tooltip"
          role="presentation"
        >
          {config.description}
        </div>
      </Tooltip>
    );
    return (
      <OverlayTrigger
        placement="top"
        overlay={descriptionTooltip}
        trigger="hover"
      ><i role="presentation" className="glyphicon glyphicon-question-sign" />
      </OverlayTrigger>
    );
  };

  render() {
    const { isEdited, config } = this.state;
    return (
      <tr className="mt-3" data-test-tag="app-config-table-row">
        <td data-test-tag="app-config-name">{config.key} {config.description && this.renderDescriptionTooltip()}</td>
        <td>
          <FormControl
            data-test-tag="app-config-value-input"
            type="text"
            placeholder="Add config value"
            defaultValue={config.value}
            onChange={this.changeConfigValue}
          />
        </td>
        <td>
          <Button
            data-test-tag="app-config-save-button"
            onClick={this.handleSave}
            disabled={!isEdited}
          >
            Save
          </Button>
        </td>
      </tr>
    );
  }
}

Config.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
  showToastrSuccess: PropTypes.func,
};

Config.defaultProps = {
  config: {},
  updateConfig: () => {},
  showToastrSuccess: () => {},
};

Config.displayName = 'Config';
