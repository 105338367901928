import * as api from '../../api/api';
import * as types from '../../constants/actionTypes';

const saveUserRole = user => dispatch => api
  .saveUserRole(user).then(res => dispatch({
    type: types.SAVE_USER_ROLE,
    payload: { res },
  }));

const fetchUsers = params => dispatch => api
  .getUsers(params).then((users) => {
    dispatch({
      type: types.FETCH_USERS,
      payload: {
        users,
      },
    });
    dispatch({
      type: types.FETCH_USERS_SUCCESSFUL,
    });
  }).catch((error) => {
    const { response: { status = 0 } = {} } = error || {};
    if (status === 403 || status === 401) {
      dispatch({
        type: types.FETCH_USERS_ACCESS_DENIED,
      });
    }
  });

const fetchUser = id => dispatch => new Promise((resolve, reject) => {
  api.getUser(id).then((res) => {
    dispatch({
      type: types.FETCH_USER,
      payload: {
        res,
      },
    });
    dispatch({
      type: types.FETCH_USER_SUCCESSFUL,
    });
    resolve({ payload: { res } });
  }).catch((error) => {
    const { response: { status = 0 } = {} } = error || {};
    if (status === 403 || status === 401) {
      dispatch({
        type: types.FETCH_USER_ACCESS_DENIED,
      });
    }
    reject(error);
  });
});

const searchUsers = params => dispatch => api
  .searchUsers(params).then(users => dispatch({
    type: types.SEARCH_USERS,
    payload: { users },
  }));

export default {
  saveUserRole,
  fetchUsers,
  searchUsers,
  fetchUser,
};
