import * as types from '../../constants/actionTypes';
import initialState from '../initialState';

export default function (state = initialState.configs, action = {}) {
  switch (action.type) {
    case types.FETCH_APP_CONFIGS: {
      return ({
        ...state,
        configs: action.payload.configs,
      });
    }
    case types.FETCH_APP_CONFIGS_SUCCESSFUL: {
      return ({
        ...state,
        fetchAppConfigsSuccess: true,
      });
    }
    case types.FETCH_APP_CONFIGS_ACCESS_DENIED: {
      return ({
        ...state,
        fetchAppConfigsAccessDenied: true,
      });
    }
    case types.UPDATE_APP_CONFIG: {
      const index = state.configs.findIndex(config => config.key === action.payload.config.key);
      const updatedConfigs = state.configs;
      updatedConfigs.splice(index, 1, action.payload.config);
      return ({
        ...state,
        configs: updatedConfigs,
      });
    }
    default: {
      return state;
    }
  }
}
