import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavBar } from '@tooling-common/components';
import { checkAuthentication as checkOktaAuthentication } from './okta';
import LoadingWrapper from '../layouts/LoadingWrapper';

export class AuthenticatedRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
    };
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  checkAuthentication() {
    const { authenticated } = this.state;
    return checkOktaAuthentication().then((isAuthenticated) => {
      if (isAuthenticated !== authenticated) {
        this.setState({ authenticated: isAuthenticated });
      }
      return isAuthenticated;
    });
  }

  render() {
    const { permissions, user } = this.props;
    const { authenticated } = this.state;

    if (authenticated === null || !authenticated) {
      return <LoadingWrapper loading loadingClassName="app-auth-loading" />;
    }

    if (permissions && permissions.length > 0) {
      const permSet = new Set(user.permissions);
      const intersectionSet = permissions.filter(n => permSet.has(n));

      const allowed = intersectionSet.length > 0;
      if (!allowed) {
        return null;
      }
    }

    const {
      path, children, component, render, info, history, idToken,
    } = this.props;

    let buildInfo = JSON.stringify(info, null, 2);
    buildInfo = buildInfo.substring(1, buildInfo.length - 1);

    if (component) {
      return (
        <div className="content">
          {idToken && (
            <NavBar
              history={history}
              tooltip={buildInfo}
              token={idToken}
            />
          )}
          <Route path={path} component={component} />
        </div>
      );
    }
    if (render) {
      return <Route path={path} render={render} />;
    }
    if (children) {
      return (
        <Route path={path}>
          {children}
        </Route>
      );
    }
    return null;
  }
}

AuthenticatedRoute.propTypes = {
  /**
   * The path to route to.
   */
  path: PropTypes.string.isRequired,
  permissions: PropTypes.array,
  user: PropTypes.object,
  render: PropTypes.bool,
  component: PropTypes.func,
  info: PropTypes.object,
  idToken: PropTypes.string,
};

AuthenticatedRoute.defaultProps = {
  permissions: [],
  user: {},
  component: () => {},
  render: false,
  info: {},
  idToken: '',
};

function mapStateToProps(state) {
  const { info, idToken } = state.app;
  return {
    info,
    idToken,
  };
}

export default connect(mapStateToProps, null)(withRouter(AuthenticatedRoute));
